import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Link } from "gatsby";

const FeatureGrid = ({ gridItems }) => {
  //console.log("gridItems", gridItems);
  function slugify(str) {
    const from = "ąàáäâãåæćęęèéëêìíïîłńòóöôõøśùúüûñçżź",
      to = "aaaaaaaaceeeeeeiiiilnoooooosuuuunczz",
      regex = new RegExp(
        "[" + from.replace(/([.*+?^=!:${}()|[\]\\])/g, "\\$1") + "]",
        "g"
      );

    if (str === null) return "";

    str = String(str)
      .toLowerCase()
      .replace(regex, function (c) {
        return to.charAt(from.indexOf(c)) || "-";
      });

    return str
      .replace(/[^\w\s-]/g, "")
      .replace(/([A-Z])/g, "-$1")
      .replace(/[-_\s]+/g, "-")
      .toLowerCase();
  }
  return (
    <div className="columns is-multiline">
      {gridItems.map(item => (
        <div key={item.text} className="column is-4">
          <div className="card">
            {/* <Link to={"/" + slugify(item.text)}> */}
            <div className="card-image">
              <div className="image ">
                <PreviewCompatibleImage imageInfo={item} />
              </div>
            </div>
            <div className="card-content">
              <div className="media">
                <div className="media-left">
                  {/* <figure className="image is-48x48">
                          <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
                        </figure> */}
                </div>
                <div className="media-content">
                  <p className="title is-4">
                    {/* John Smith */}
                    {/* {item.title} */}
                    {item.text}
                  </p>

                  {/* <p className="subtitle is-6">
                      {item.text}
                    </p> */}
                </div>
              </div>

              {/* <div className="content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          Phasellus nec iaculis mauris. <a>@bulmaio</a>.
                          <a href="#">#css</a> <a href="#">#responsive</a>
                      <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
                    </div> */}
            </div>
            {/* </Link> */}
          </div>
        </div>
      ))}
    </div>
  );
};

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string
    })
  )
};

export default FeatureGrid;
