import { useStaticQuery, graphql } from "gatsby";
//https://community.netlify.com/t/deploying-a-gatsby-site-on-netlify-using-the-gatsby-source-instagram-plugin-failing-on-build-time-issue-with-netlify-fetching-data-from-instagram-cannot-reproduce-locally/15957

//it your choice to choose insurance company

const useInstagram = () => {
  const data = useStaticQuery(graphql`
  query InstagramPicturesQuery {
    allInstaNode(sort: {order: DESC, fields: timestamp},limit:12) {
      nodes {
        id
        caption
        username
        comments
        localFile {
          childImageSharp {
            fluid(srcSetBreakpoints: 10, maxWidth: 250, maxHeight: 250) {
              originalImg
              originalName
              srcSet
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
      `);
  return data.allInstaNode.nodes.map(
    node => ({
      ...node.localFile.childImageSharp,
      id: node.id,
      caption: node.caption,
      username: node.username
    })
  )
}

export default useInstagram;
