import React, { Component } from 'react'
import Image from 'gatsby-image'
import styled from "styled-components"
import useInstagram from '../hooks/use-instagram'

const PhotosWrapper = styled.div`
display: grid;
/* grid-gap: 15px; */
grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
grid-template-rows: 250px 250px 250px;
justify-content: center;
justify-items: center;
@media (max-width: 400px){
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-rows: 100px 100px 100px 100px;
    }
`;

const InstagramPhoto = styled.div`
    box-shadow: 0;
    width: 100%;
    height: 100%;
    transition: 200ms box-shadow linear;
    :focus,
    :hover{
        box-shadow: 0 2px 14px #22222244;
        z-index:10;
    }
`;



export default function Instagram() {

    const instaPhotos = useInstagram();
    const { username } = instaPhotos[0];


    return (
        <div className="container">
            <br />
            <h3 className="title">
                {/* Instagram posts from @{username} */}
                Instagram posts from @broadwaydynamic
            </h3>
            <p className='content'>
                <a
                    className="button is-small is-link is-outlined"
                    // href={`https://www.instagram.com/${username}/`}>
                    href={`https://www.instagram.com/broadwaydynamic/`}>
                    See more on Instagram &rarr;
                </a>
            </p>

            <PhotosWrapper>{instaPhotos.map(photo => (
                <InstagramPhoto
                    key={photo.id}
                // href={`https://www.instagram.com/p/${photo.id}`}
                >
                    <Image
                        style={{ width: '100%', height: '100%', marginTop: '0' }}
                        fluid={photo.fluid} alt={photo.caption} />
                </InstagramPhoto>
            ))}</PhotosWrapper>
            <br />

        </div>
    )
}

