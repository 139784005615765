import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Instagram from "../components/Instagram"
import Layout from '../components/Layout'
import Features from '../components/Features'
// import BlogRoll from '../components/BlogRoll'
import styled from 'styled-components'
import Transrobotics from '../assets/Fonts/sf-transrobotics-extended-bold-italic.ttf'
//TODO: reviews add to page
//TODO: Environmentally Friendly Car Painting Service

const Heading = styled.h1`

    @font-face {
        font-family: Transrobotics;
        src: url(${Transrobotics});
      }
    font-family: Transrobotics;
    /* background-color: #003168; */
    color: white;
    line-height: 1;
    padding: 0.1em;
    font-size: 4rem;
    opacity: 0.8;
    width: 500px;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 400px){
      width: 350px;
      font-size: 2.4rem;
    }
    /* text-shadow: black 6px 6px 1px; */
    text-shadow: black 6px 6px 1px, black 6px 6px 7px;
    /* text-shadow: 0px 0px 5px #fff, 0px 0px 7px #fff; */
    animation-delay: 1s;
    animation: blur 6s ease;
    animation-fill-mode: forwards;

      /* animation-iteration-count: 2; */
    @keyframes blur {
  from {
    color: white;
    text-shadow:0px 0px 10px #fff,
      0px 0px 10px black,
      0px 0px 25px black,
      0px 0px 25px black,
      0px 0px 25px black,
      0px 0px 25px black,
      0px 0px 25px black,
      0px 0px 25px black,
      0px 0px 50px black,
      0px 0px 50px black,
      0px 0px 50px #444444,
      0px 0px 150px #444444,
      0px 10px 100px #444444,
      0px 10px 100px #444444,
      0px 10px 100px #444444,
      0px 10px 100px #444444,
      0px -10px 100px #444444,
      0px -10px 100px #444444;
      }
      to{
        color: silver;
      }

  }

`;
/* const Marquee = styled.div`
    background-image: linear-gradient(180deg, rgba(0,49,104,1) 50%, rgba(0,61,210,1) 100%);
    font-size: 3rem;
    color: silver;
    line-height: 1;
    width: 100%;
    padding: 1rem 0rem;
    display: grid;
    justify-content: stretch;
    align-items: stretch;
    align-content:stretch;
    text-align: center;
    marquee{
      @font-face {
            font-family: Transrobotics;
            src: url(${Transrobotics});
          }
          font-family: Transrobotics;
          text-shadow: black 6px 6px 1px;
          text-transform: uppercase;
    }
    p{
      color: red;
      font-size: 1.5rem;
    }
    @media (max-width: 400px){
      font-size: 2rem;
    }
`;
 */
const Effect = styled.div`
    background-image: linear-gradient(180deg, rgba(0,49,104,1) 50%, rgba(0,61,210,1) 100%);
    width: 100%;
    padding: 1rem 0rem;
    display: grid;
    justify-content: stretch;
    align-items: stretch;
    align-content:stretch;
    text-align: center;
    p{
      @font-face {
            font-family: Transrobotics;
            src: url(${Transrobotics});
          }
          font-family: Transrobotics;
          /* text-shadow: black 6px 6px 1px; */
          text-transform: uppercase;
          position: relative;
          text-transform: uppercase;
          font-size: 3rem;
          letter-spacing: 4px;
          overflow: hidden;
          /* background: linear-gradient(90deg, #000, #fff, #000); */
          background: linear-gradient(90deg, rgba(0,49,104,1), #fff, rgba(0,61,210,1));
          background-repeat: no-repeat;
          background-size: 80%;
          animation: animate 6s linear infinite;
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255, 255, 255, 0);
          @keyframes animate {
            0% {
              background-position: -400%;
            }
            100% {
              background-position: 400%;
            }
          }
    }
    /* height: 200px; */
    @media (max-width: 400px){
      p{
        font-size: 1rem;
      }
    }
`;
const OverflowHeading = styled.div`
    /* custom */
    /* background-image: linear-gradient(to top, #ddbbffdd 2rem, #ddbbff00);
    height: 100%; */
    /*end custom */

    display: grid;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: 400px){
      height: 400px;
    }
    @media (min-width: 800px){
      background-position: top left;
      background-position: 0px 830px;
      /* background-attachment: fixed; */
      height: 750px;
    }
`;

const Subheading = styled.h2`
    /* background-color: #003168; */
    color: red;
    line-height: 1;
    opacity: 0.6;
    text-align: center;
    font-weight: 900;
    font-size: 3.5rem;
    text-shadow: black 2px 2px 1px;
    @media (max-width: 400px){
      font-size: 2rem;
    }
`;




export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
    <div>
      <OverflowHeading
        className="full-width-image"
        style={{
          backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image
            })`

        }}
      >
        <div>
          <Heading>
            {title}
            {/* TODO:Solid work and trust at a reasonable price */}
          </Heading>
          <Subheading>
            {subheading}
          </Subheading>
        </div>
      </OverflowHeading>
      {/* custom */}
      {/* <Marquee>
        <marquee behavior="scroll" direction="left" scrollamount="5">
          {title}
        </marquee>
      </Marquee> */}
      <Effect>
        <p>
          {title}
        </p>
        {/* <p>{subheading}</p> */}
      </Effect>
      {/* end custom */}
      <section className="section section--gradient">

        <div className="container">

          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">

                  <div className="tile">
                    <h2 className="title">{mainpitch.title}</h2>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle">{mainpitch.description}</h3>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />
                {/* <div className="columns">
                    <div className="column is-12 has-text-centered ">
                      <Link className="button is-link is-outlined" to="/auto-body-and-paint-services">
                        See our servises
                    </Link>
                    </div>
                  </div> */}
                {/* <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      Latest stories
                  </h3>
                    <BlogRoll />
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog">
                        Read more
                    </Link>
                    </div>
                  </div> */}
              </div>


              {/* custom tiles */}
              <div className="content">
                <div className="tile is-ancestor">
                  <div className="tile is-vertical is-8">
                    <div className="tile">
                      <div className="tile is-parent is-vertical">
                        <article className="tile is-child notification ">
                          <p className="title">Free Estimates</p>
                          {/* <p className="subtitle">Top tile</p> */}
                        </article>
                        <article className="tile is-child notification ">
                          <p className="title">24 / 7 Hour Vehicle Towing Services</p>
                        </article>
                      </div>
                      <div className="tile is-parent">
                        <article className="tile is-child notification">
                          <p className="title">Body Services</p>
                          <p className="content">· Dent and scratch removal</p>
                          <p className="content">· Carbon fiber restoration</p>
                          <p className="content">· Complete paint correction</p>
                          <p className="content">· Aluminium welding </p>

                          <div className="content">

                          </div>
                        </article>
                      </div>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child notification">
                        <p className="title">Company Value</p>
                        <p className="content">· Direct repair for many major insurances</p>
                        <p className="content">· Using only the finest equipment and materials</p>
                        <p className="content">· Family operated business</p>
                        <p className="content">· Honest, trustworthy and reliable</p>

                        <div className="content">

                        </div>
                      </article>
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <article className="tile is-child notification ">

                      <p className="title">Paint Services:</p>
                      <p className="content">· Custom design, airbrushing and pin-stripping </p>
                      <p className="content">· Computerized paint matching to original factory specifications</p>

                      <br />
                      <p className="title">Mechanic Services:</p>
                      <p className="content">· Repair alignments and suspension</p>
                      <p className="content">· Wheel repair & replacement</p>

                      <br />
                      <p className="title">Other Services:</p>
                      <p className="content">· computerized diagnostics </p>
                      <p className="content">· pick-up and drop-off service</p>
                      <p className="content">· headlamp restoration</p>
                      <div className="content">

                      </div>

                    </article>
                  </div>

                </div>
              </div>

              {/* end custom tiles */}
              <br />

              {/*  info tiles */}
              <div className="tile is-ancestor">
                <div className="tile is-parent">
                  <article className="tile is-child notification is-warning">
                    <div className="content">
                      <p className="title">Insurance</p>
                      <p className="subtitle">We work with and maintain great relationships with many insurance companies.</p>
                      <div className="content">
                        <p className="content">(GEICO, Allstate, Progressive, State Farm and more.) </p>
                        <p className="content">· We make every effort to provide your insurance company with all of the information they need to process your insurance claim quickly and fairly. </p>
                        <p className="content">· Our extensive knowledge and experience in the car insurance industry will make the claim process easy and efficient.</p>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="tile is-vertical is-8">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child notification is-warning">
                        <p className="title">It’s the law!</p>
                        <p className="subtitle">Know your legal rights!</p>
                        <p className="content">· In most cases, you don't have to use the body shop that the insurance company recommended.</p>
                      </article>
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <article className="tile is-child notification is-info">
                      <p className="title">The choice is yours.</p>
                      <p className="subtitle">Choose a repair shop that you feel confident in.</p>
                      <p className="content">· Remember that you are the owner of the vehicle and you have the right to choose where to repair your vehicle. Use the services of a body shop that:
                      · Is certified
                      · Provides quality
                      · Has a strong reputation of excellence
                      </p>
                      <div className="content">

                      </div>
                    </article>
                  </div>
                </div>
              </div>
              {/* end info tiles */}

              <br />

              {/* Instagram */}
              <Instagram />
              {/* end Instagram */}

            </div>
          </div>


        </div>
      </section>
    </div >
  )

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
          markdownRemark(frontmatter: {templateKey: {eq: "index-page" } }) {
          frontmatter {
          title
        image {
          childImageSharp {
          fluid(
            maxWidth: 2048,
            quality: 100
                srcSetBreakpoints: [340, 520, 800]
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid
                src
                srcSet
                originalImg
                originalName
        }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
          image {
            childImageSharp {
              fluid(
                maxWidth: 800
                maxHeight: 450
                quality: 90
                srcSetBreakpoints: [340, 520, 800]
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid_withWebp
                src
                srcSet
                originalImg
                originalName
              }
            }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`

//TODO: podstrona estimate
//TODO: zdjecia motocykli
//TODO: dodac call to action na stronie glownej
//TODO: dodac fotke office z zewnatrz i wewnatrz
//TODO: logo vector
//TODO: sprawdzic teksty zlecic
//TODO: film zlecic peter
//TODO: wiecej fotek pod kategorie
//TODO: scratches removal
//TODO: aluminium welding
// Scratch Removal Service
// Car Scratch Removal Service
// Motorcycle Scratch Removal Service


//!Instagram gallery:
//!Insurance info
